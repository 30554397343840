import { XF } from "./XF";

XF.Element.extend("lightbox", {
  handleContainerZoom() {
    if (!this.options.lbSingleImage || !this.options.lbContainerZoom) {
      return;
    }

    const $container = this.$target;
    const $image = $container.find("img[data-zoom-target=1]");

    if ($image.parents("a").length) {
      return;
    }

    // eslint-disable-next-line eqeqeq
    if ($image.length != 0 && $image[0].dataset.disableLightbox == 1) {
      return;
    }

    // timeout to allow animations to finish (e.g. quick edit)
    setTimeout(() => {
      const onLoad = () => {
        if (this.isImageNaturalSize($image)) {
          $container.removeClass("lbContainer--canZoom");
        } else {
          $container.addClass("lbContainer--canZoom");
        }
      };
      if (!$image.prop("complete")) {
        $image.on("load", onLoad);
      } else {
        onLoad();
      }
    }, 500);
  },
});
